import axios from "utils/http";
import Bucket from "@/config/obs.config.js";
import { ElMessage, ElLoading } from "element-plus";

const createOBS = (
  callback,
  onComplete,
  accept,
  onError,
  fileSize,
  fileType
) => {
  return new Promise((resolve, reject) => {
    document.getElementById("obschooseAvatar");
    let loading;
    let acceptText = accept || ".jpg,.jpeg,.png,.JPEG,.BMP,.GIF,.WEBP,.hdr";
    let nowFileSize = fileSize || 50;
    axios
      .post("api-personal/laozi/v3/common/unauth/getHuaweiParam", {})
      .then((res) => {
        const obsUploader = new OBSUploader({
          input_file: "chooseAvatar",
          multiple: true,
          chunked: true,
          ak: res.datas.ak,
          sk: res.datas.sk,
          key: Bucket.model.key[2],
          server: res.datas.endpoint,
          bucket: Bucket.model.bucket,
          accept: acceptText,
          fileLimit: 100,
          fileSingleSizeLimit: 1024 * 1024 * nowFileSize,
          onFilesQueued: function () {
            loading = ElLoading.service({ fullscreen: true });
            return true;
          },
          onUploadSuccess: function (file, objectKey) {
            loading.close();
            //设置贴图为公开权限，通过拼接拿取图片URL
            obsUploader._setObjectAcl(
              objectKey,
              Bucket.model.bucket,
              "public",
              () => {
                var url =
                  "https://" +
                  Bucket.model.bucket +
                  ".obs.cn-east-2.myhuaweicloud.com/" +
                  objectKey;
                callback({ file, objectKey, url });
              }
            );
          },
          onComplete: (fileList) => {
            onComplete && onComplete(fileList.length);
          },
          onError: function (type) {
            if (type == "Q_TYPE_DENIED") {
              ElMessage.warning(
                "文件格式不正确！（仅支持" + acceptText + "文件）"
              );
            } else if (type == "Q_EXCEED_SIZE_LIMIT") {
              ElMessage.warning(
                "文件格式大小超出限制（" + nowFileSize + "MB）!"
              );
            }
            onError && onError();
            return false;
          },
          onUploadError: function (file, error) {
            loading.close();
          },
        });
        resolve(obsUploader);
      });
  });
};

const imgTest = (imgUrl) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api-personal/contentSecurity/imageSyncScan", { imgUrl })
      .then((res) => {
        if (res.resp_code != 60000) {
          if (res.resp_code == "task process fail:589") {
            ElMessage.warning("图片过大，请重新上传");
          } else {
            ElMessage.warning(res.resp_msg);
          }
          reject();
        }
        resolve();
      });
  });
};

export { createOBS, imgTest };
